import React from 'react';
import SkyArtistCH from './skyArtist_ch';

function SkyArtist3() {
  return (
    <SkyArtistCH></SkyArtistCH>
  );
}


export default SkyArtist3;
