import React from 'react';
import SkyArtistCH from './skyArtist_ch';

function SkyArtist1() {
  return (
    <SkyArtistCH></SkyArtistCH>
  );
}


export default SkyArtist1;
