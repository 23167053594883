import React from 'react';
import SkyArtist from './skyArtist';

function SkyArtist1() {
  return (
    <SkyArtist></SkyArtist>
  );
}


export default SkyArtist1;