import React from 'react';
import SkyArtist from './skyArtist';

function SkyArtist2() {
  return (
    <SkyArtist></SkyArtist>
  );
}


export default SkyArtist2;